import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';

import vueDebounce from 'vue-debounce';

import store from '@/store';
import router from '@/router';

Vue.config.productionTip = false;

Vue.use(vueDebounce);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app');
