<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="410"
    >
      <v-card :loading="loading">
        <template slot="progress">
          <v-progress-linear
            height="10"
            indeterminate
          />
        </template>
        <v-card-title class="headline">
          Set token before dashboard using
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="token"
            :error-messages="invalidTokenMessages"
            label="Token"
            required
            @keyup.enter="setToken"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            :disabled="loading"
            @click="setToken"
          >
            Set
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-extend-layouts v-if="!loading" />
  </v-app>
</template>

<script>
  import VueExtendLayouts from 'vue-extend-layout';
  import { checkToken } from '@/api/tokenMethods';

  export default {
    name: 'App',
    components: {
      VueExtendLayouts,
    },
    data () {
      return {
        dialog: true,
        loading: false,
        token: '',
        invalidTokenMessages: [],
      };
    },
    mounted () {
      if (localStorage.getItem('token')) {
        this.token = localStorage.getItem('token');
        this.setToken();
      }
    },
    methods: {
      async setToken () {
        try {
          if (this.token) {
            localStorage.setItem('token', this.token);
            this.loading = true;
            await checkToken();
            await Promise.all([
              this.$store.dispatch('credentials/SET_NO_STATS_CREDENTIALS_LIST'),
              this.$store.dispatch('problems/LOAD_PROBLEMS'),
              this.$store.dispatch('workers/LOAD_WORKERS_LIST', { offset: 0, limit: 100, isAddCreds: true, apollo: false }),
              this.$store.dispatch('workers/SET_WORKERS_FILTER'),
              this.$store.dispatch('workers/SET_VERSION'),
              this.$store.dispatch('apollo/SET_APOLLO_CLIENTS_LIST'),
              // this.$store.dispatch('apollo/SET_APOLLO_CALLS_LIST'),
              // this.$store.dispatch('emails/SET_EMAILS_LIST'),
            ]);

            this.loading = false;
            this.dialog = false;
          }
          this.invalidTokenMessages = ['Set token!'];
        } catch (e) {
          console.error(e);
          this.loading = false;
          this.invalidTokenMessages = [e.message];
        }
      },
    },
  };
</script>

<style>
</style>
