import { useHttp } from '@/middleware/httpMiddleware';

async function getWorkflows (
  offset = 0,
  limit = 5,
  credentials = '',
  is_add_stats = false,
  status = 'active'
) {
  const data = await useHttp(
    '/admin/workflows',
    'GET',
    null,
    {
      offset,
      limit,
      credentials,
      is_add_stats,
      status,
    }
  );

  return data.payload;
}

export {
  getWorkflows,
};
