import { getWorkflows } from '@/api/workflowsMethods';

const state = () => ({
  workflows: [],
  noStatsWorkflows: [],
});

const getters = {
  workflows: state => {
    return state.workflows;
  },
  noStatsWorkflows: state => {
    return state.noStatsWorkflows;
  },
  filters: state => {
    return state.filters;
  },
};

const mutations = {
  SET_NO_STATS_WORKFLOWS (state, payload) {
    state.noStatsWorkflows = payload;
  },

};

const actions = {

  async SET_NO_STATS_WORKFLOWS ({ commit, state }, credentials) {
    if (credentials) {
      const workflows = await getWorkflows(0, 5, credentials, false);
      state.noStatsWorkflows = workflows;
    } else {
      state.noStatsWorkflows = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
