import Vue from 'vue';
import Vuex from 'vuex';

import credentials from '@/store/modules/credentials';
import workflows from '@/store/modules/workflows';
import problems from '@/store/modules/problems';
import tasks from '@/store/modules/tasks';
import workers from '@/store/modules/workers';
import apollo from '@/store/modules/apollo';
import emails from '@/store/modules/emails';
import proxy from '@/store/modules/proxy';
import schedule from '@/store/modules/schedule';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    credentials,
    problems,
    tasks,
    workers,
    apollo,
    emails,
    workflows,
    proxy,
    schedule,
  },
  state: {
    tasksTypes: [
      { label: 'Task type', value: '' },
      { label: 'apollo_enrich_profile', value: 'apollo_enrich_profile' },
      { label: 'send_webhook', value: 'send_webhook' },
      { label: 'li_auth_auto', value: 'li_auth_auto' },
      { label: 'li_accept_invites', value: 'li_accept_invites' },
      { label: 'li_fetch_me', value: 'li_fetch_me' },
      { label: 'li_fetch_connections', value: 'li_fetch_connections' },
      { label: 'li_fetch_dialogs', value: 'li_fetch_dialogs' },
      { label: 'li_make_search', value: 'li_make_search' },
      { label: 'li_logout', value: 'li_logout' },
      { label: 'li_invite_event', value: 'li_invite_event' },
      { label: 'li_send_message', value: 'li_send_message' },
      { label: 'li_fetch_activity', value: 'li_fetch_activity' },
      { label: 'li_connect_profile', value: 'li_connect_profile' },
      { label: 'li_connect_profile_api', value: 'li_connect_profile_api' },
      { label: 'li_fetch_profile_api', value: 'li_fetch_profile_api' },
      { label: 'li_fetch_profile_api_html', value: 'li_fetch_profile_api_html' },
      { label: 'li_convert_navigator_link', value: 'li_convert_navigator_link' },
      { label: 'li_convert_recruiter_link', value: 'li_convert_recruiter_link' },
      { label: 'li_enter_security_code', value: 'li_enter_security_code' },
      { label: 'li_set_proxy', value: 'li_set_proxy' },
      { label: 'li_send_inmail', value: 'li_send_inmail' },
      { label: 'li_send_sales_inmail', value: 'li_send_sales_inmail' },
      { label: 'li_fetch_post', value: 'li_fetch_post' },
      { label: 'li_fetch_navigator_list', value: 'li_fetch_navigator_list' },
      { label: 'li_fetch_profile', value: 'li_fetch_profile' },
      { label: 'li_fetch_profile', value: 'li_fetch_profile' },
      { label: 'li_endorse_skills', value: 'li_endorse_skills' },
      { label: 'li_connect_by_email', value: 'li_connect_by_email' },
      { label: 'li_fetch_company', value: 'li_fetch_company' },
      { label: 'li_fetch_profile_dialog', value: 'li_fetch_profile_dialog' },
      { label: 'li_like_post', value: 'li_like_post' },
      { label: 'li_follow_profile', value: 'li_follow_profile' },
      { label: 'li_fetch_recruiter_project', value: 'li_fetch_recruiter_project' },
      { label: 'li_fetch_posts', value: 'li_fetch_posts' },
      { label: 'li_convert_recruiter_link', value: 'li_convert_recruiter_link' },
      { label: 'li_join_event', value: 'li_join_event' },
      { label: 'li_join_group', value: 'li_join_group' },
      { label: 'li_send_event_message', value: 'li_send_event_message' },
      { label: 'send_email', value: 'send_email' },
      { label: 'ap_fetch_people', value: 'ap_fetch_people' },
      { label: 'ap_fetch_me', value: 'ap_fetch_me' },
      { label: 'ap_auth', value: 'ap_auth' },
    ],
  },
  getters: {
    tasksTypes: state => state.tasksTypes.sort((a, b) => a.label.localeCompare(b.label)),
  },
  mutations: {},
  actions: {},
});

export default store;
